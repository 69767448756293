import { loadingController } from '@/components/global-loading/global-loading-controller'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { Zero } from '@/constants'
import { bigNumberHelper } from '@/helpers/bignumber-helper'
import { FarmHandler } from '@/helpers/farm-handler'
import { promiseHelper } from '@/helpers/promise-helper'
import { StakingHandler } from '@/helpers/staking-handler'
import { walletStore } from '@/stores/wallet-store'
import { FixedNumber } from '@ethersproject/bignumber'
import { action, computed, IReactionDisposer, observable, runInAction, when } from 'mobx'
import { asyncAction } from 'mobx-utils'
import moment from 'moment'
import { Subject, timer } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

export class StakingViewModel {
  _disposers: IReactionDisposer[] = []
  private _unsubcrible = new Subject()

  @observable stakeDialogInput = ''
  @observable isShowStakeDialog = false
  @observable isDialogStaking = false
  @observable isDialogLoading = false

  @observable approved = false
  @observable approving = false

  @observable lockDuration = moment.duration(0, 'second') // in seconds

  @observable stakedLP = FixedNumber.from('0')
  @observable userLPBalance = FixedNumber.from('0')
  @observable lastStakeTime: moment.Moment | null = null
  @observable totalLockedAmount = FixedNumber.from('0')
  stakingHandler?: StakingHandler

  @observable tokenPrice = FixedNumber.from('0')

  constructor() {
    this.loadData()
  }

  destroy() {
    this._unsubcrible.next()
    this._unsubcrible.complete()
    this._disposers.forEach(d => d())
  }

  async loadData() {
    const stakingHandler = new StakingHandler()
    const farmHandler = new FarmHandler()
    this.stakingHandler = stakingHandler
    for (let index = 0; index < 3; index++) {
      try {
        await stakingHandler.load()
        break
      } catch (error) {
        if (index === 2) {
          snackController.error(error.msg || error.message || error)
        } else {
          await promiseHelper.delay(1000)
        }
      }
    }
    farmHandler.load().then(async () => {
      const { token2UsdPrice } = await farmHandler.getFarmLiquidityAndApy()
      runInAction(() => (this.tokenPrice = token2UsdPrice))
    })
    this.lockDuration = stakingHandler.lockDuration!
    timer(0, 30000)
      .pipe(takeUntil(this._unsubcrible))
      .subscribe(async () => {
        const { lockedAmount } = await stakingHandler.getLockedAmount()
        runInAction(() => {
          this.totalLockedAmount = lockedAmount
        })
      })

    this._disposers.push(
      when(
        () => walletStore.connected,
        async () => {
          if (walletStore.chainId === 56) {
            stakingHandler.injectMetamask(walletStore.web3!)
            stakingHandler.approved(walletStore.account).then(approved => runInAction(() => (this.approved = approved)))
            timer(0, 5000)
              .pipe(takeUntil(this._unsubcrible))
              .subscribe(async () => {
                this.fetchPoolInfo()
              })
          }
        }
      )
    )
  }

  async fetchPoolInfo() {
    if (!walletStore.account || !this.stakingHandler) {
      this.stakedLP = FixedNumber.from('0')
    } else {
      const { lockedAmount } = await this.stakingHandler.getLockedAmount()
      this.totalLockedAmount = lockedAmount
      const [{ amount, lastStakeTime }, userLPBalance] = await Promise.all([
        this.stakingHandler.getUserInfo(walletStore.account),
        this.stakingHandler.getUserLPBalance(walletStore.account)
      ])
      this.stakedLP = amount
      this.lastStakeTime = lastStakeTime
      this.userLPBalance = userLPBalance
    }
  }

  @asyncAction *approve() {
    this.approving = true
    try {
      yield this.stakingHandler!.approve(walletStore.account)
      this.approved = true
    } catch (error) {
      snackController.error(error.message)
    }
    this.approving = false
  }

  @action.bound changeStakeDialogInput(input) {
    this.stakeDialogInput = input
  }

  @action.bound requestStakeLP() {
    this.isShowStakeDialog = true
    this.isDialogStaking = true
  }

  @action.bound requestUnstakeLP() {
    this.isShowStakeDialog = true
    this.isDialogStaking = false
  }

  @action.bound maximum() {
    this.stakeDialogInput = this.maxDialogStakeBalance.toString()
  }

  @asyncAction *confirm() {
    this.isDialogLoading = true
    try {
      if (this.isDialogStaking) {
        yield this.stakingHandler!.stakeLP(walletStore.account, this.stakeDialogInput)
        snackController.success('Stake BSL successful')
      } else {
        yield this.stakingHandler!.unstakeLP(walletStore.account, this.stakeDialogInput)
        snackController.success('Unstake BSL successful')
      }
      this.fetchPoolInfo()
      this.stakeDialogInput = '0'
    } catch (err) {
      snackController.error(err.message)
    } finally {
      this.isDialogLoading = false
    }
  }

  @action.bound cancelStakeDialog() {
    this.stakeDialogInput = '0'
    this.isShowStakeDialog = false
  }

  @computed get isStaked() {
    return bigNumberHelper.gt(this.stakedLP, FixedNumber.from('0'))
  }

  @computed get maxDialogStakeBalance() {
    return this.isDialogStaking ? this.userLPBalance : this.stakedLP
  }

  @computed get lockInDays() {
    return this.lockDuration.asDays()
  }

  @computed get tvl() {
    return this.totalLockedAmount.mulUnsafe(this.tokenPrice)
  }

  @computed get canUnstakeTime() {
    if (this.lastStakeTime) {
      return this.lastStakeTime.clone().add(this.lockDuration)
    }
    return null
  }

  @computed get validDialogInputAmount() {
    try {
      const amount = FixedNumber.from(this.stakeDialogInput)
      if (!this.isDialogStaking && this.canUnstakeTime && this.canUnstakeTime.isAfter(moment())) return false
      if (amount.isNegative() || amount.isZero()) return false
      if (this.isDialogStaking) {
        return bigNumberHelper.lte(amount, this.userLPBalance)
      } else {
        return bigNumberHelper.lte(amount, this.stakedLP)
      }
    } catch (error) {
      return false
    }
  }
}
