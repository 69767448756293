/* eslint-disable prettier/prettier */
import { FixedNumber } from '@ethersproject/bignumber'
import moment from 'moment'
import Web3 from 'web3'
import { blockchainHandler } from '@/blockchain'
import { promiseHelper } from './promise-helper'

const web3 = blockchainHandler.getWeb3(56)!

async function sendRequest(fx, from) {
  return await new Promise((resolve, reject) => {
    fx.send({ from })
      .on('receipt', () => resolve(''))
      .on('error', error => reject(error))
  })
}

export class StakingHandler {
  farmContract: any
  LPTokenContract: any
  lockDuration?: moment.Duration

  rewardToken?: string
  poolToken?: string

  constructor() {
    this.farmContract = new web3.eth.Contract(require('./farm.abi.v2.json'), process.env.VUE_APP_FARM_2_ADDRESS)
  }

  async load() {
    const rewardToken = await this.farmContract.methods.rewardToken().call()
    this.rewardToken = web3.utils.toChecksumAddress(rewardToken)
    const {
      0: poolToken,
      1: allocPoint,
      2: lastRewardBlock,
      3: accRewardPerShare,
      4: lockDuration
    } = await this.farmContract.methods.poolInfo(1).call()
    this.poolToken = web3.utils.toChecksumAddress(poolToken)
    this.lockDuration = moment.duration(lockDuration, 'seconds')
    this.LPTokenContract = new web3.eth.Contract(require('./erc20.abi.json'), this.poolToken)
  }

  injectMetamask(web3: Web3) {
    if (web3) {
      this.farmContract = new web3.eth.Contract(require('./farm.abi.v2.json'), process.env.VUE_APP_FARM_2_ADDRESS)
      this.LPTokenContract = new web3.eth.Contract(require('./erc20.abi.json'), this.poolToken)
    }
  }

  async approved(account, index = 3) {
    try {
      const allowance = await this.LPTokenContract.methods.allowance(account, process.env.VUE_APP_FARM_2_ADDRESS).call()
      return !!+Web3.utils.fromWei(allowance)
    } catch (error) {
      if (index) {
        await promiseHelper.delay(200)
        return await this.approved(account, --index)
      }
      console.error(error)
      return false
    }
  }

  async approve(account) {
    const f = this.LPTokenContract.methods.approve(
      process.env.VUE_APP_FARM_2_ADDRESS,
      web3.utils.toWei(`${2 ** 32 - 1}`)
    )
    await sendRequest(f, account)
  }
  async stakeLP(account, amount) {
    const f = this.farmContract.methods.stakeLP(1, web3.utils.toWei(`${amount.toString()}`))
    await sendRequest(f, account)
  }

  async unstakeLP(account, amount) {
    const f = this.farmContract.methods.unstakeLP(1, web3.utils.toWei(`${amount.toString()}`))
    await sendRequest(f, account)
  }
  async getUserInfo(account) {
    const { 0: amount, 1: lastStakeTime } = await this.farmContract.methods.getUserInfo(1, account).call()
    return {
      amount: FixedNumber.from(`${web3.utils.fromWei(amount)}`),
      lastStakeTime: +lastStakeTime ? moment(+lastStakeTime * 1000) : null
    }
  }
  async getUserLPBalance(account) {
    const allowance = await this.LPTokenContract.methods.balanceOf(account).call()
    return FixedNumber.from(`${web3.utils.fromWei(allowance)}`)
  }

  async getLockedAmount() {
    const { 5: amount } = await this.farmContract.methods.poolInfo(1).call()
    return { lockedAmount: FixedNumber.from(`${web3.utils.fromWei(amount)}`) }
  }
}
