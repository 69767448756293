









































































import { StakingViewModel } from '../viewmodels/staking-viewmodel'
import { Observer } from 'mobx-vue'
import { Component, Vue, Provide } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'

@Observer
@Component({
  components: {
    'staking-dialog': () => import('../dialogs/staking-dialog.vue')
  }
})
export default class Staking extends Vue {
  @Provide() vm = new StakingViewModel()
  walletStore = walletStore

  viewLPContract() {
    window.open(`https://bscscan.com/address/${this.vm.stakingHandler!.poolToken}`, '_blank')
  }
  getBSL() {
    window.open(`https://pancakeswap.finance/swap?outputCurrency=${this.vm.stakingHandler!.poolToken}`, '_blank')
  }

  destroyed() {
    this.vm.destroy()
  }
}
